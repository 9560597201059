import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { Helmet } from 'react-helmet'; // Импортируем Helmet
import '../scss/SendPhoneNumber.scss';

const brands = {
  Apple: [
    'iPhone 15 Pro Max', 'iPhone 15 Pro', 'iPhone 15',
    'iPhone 15 Plus', 'iPhone 14 Pro Max', 'iPhone 14 Pro',
    'iPhone 14', 'iPhone 14 Plus', 'iPhone 13 Pro Max',
    'iPhone 13 Pro', 'iPhone 13', 'iPhone 13 Mini',
    'iPhone 12 Pro Max', 'iPhone 12 Pro', 'iPhone 12',
    'iPhone 12 Mini', 'iPhone 11 Pro Max', 'iPhone 11 Pro',
    'iPhone 11', 'iPhone SE (2-го поколения)', 'iPhone SE (3-го поколения)',
    'iPhone X', 'iPhone XS', 'iPhone XS Max', 'iPhone XR',
    'Другое'
  ],
  Samsung: [
    'Galaxy S23 Ultra', 'Galaxy S23+', 'Galaxy S23', 
    'Galaxy Z Fold5', 'Galaxy Z Flip5', 'Galaxy Note 20 Ultra', 
    'Galaxy A54', 'Galaxy A34',
    'Другое'
  ],
  Xiaomi: [
    'Xiaomi 13 Pro', 'Xiaomi 13', 'Xiaomi 12', 
    'Xiaomi Mi 11', 'Xiaomi Redmi Note 11', 
    'Xiaomi Redmi 10', 'Другое'
  ],
  Huawei: [
    'Huawei P60 Pro', 'Huawei Mate 50 Pro', 
    'Huawei Nova 10', 'Huawei P40 Pro',
    'Другое'
  ],
  OnePlus: [
    'OnePlus 11', 'OnePlus 10 Pro', 'OnePlus Nord 2', 
    'OnePlus 9', 'OnePlus 8T', 
    'Другое'
  ],
  // Добавьте другие бренды и модели по мере необходимости
};

export function SendPhoneNumber() {
  const [formData, setFormData] = useState({
    phone: '',
    name: '',
    brand: '',
    model: '',
    type: '',
    condition: '',
    description: '',
    serviceType: '',
    otherModel: '',
    otherBrand: ''
  });

  const [error, setError] = useState('');
  const [formError, setFormError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData(prevData => ({ ...prevData, [name]: value }));

    if (name === 'phone') {
      const pattern = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
      setError(!pattern.test(value) ? 'Пожалуйста, введите действительный номер телефона в формате: +7 (XXX) XXX-XX-XX' : '');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (error || isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    try {
      console.log("Отправляемые данные:", formData);

      const response = await fetch('https://api.re-service.pro/send_message', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }

      const data = await response.json();

      if (data.status === 'success') {
        alert('Сообщение успешно отправлено!');
        setFormData({
          phone: '',
          name: '',
          brand: '',
          model: '',
          type: '',
          condition: '',
          description: '',
          serviceType: '',
          otherModel: '',
          otherBrand: ''
        });
        setError('');
      } else {
        setFormError(`Ошибка отправки сообщения: ${data.message || 'Неизвестная ошибка'}`);
      }
    } catch (err) {
      setFormError(`Ошибка при отправке сообщения: ${err.message || 'Неизвестная ошибка'}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className='SendPhoneNumber'>
      <Helmet>
        <title>Отправьте свои данные для ремонта или продажи техники</title>
        <meta name="description" content="Профессиональный ремонт и выкуп техники в Санкт-Петербурге. Оставьте свои данные и мы вам перезвоним." />
      </Helmet>
      <img className='background-image' src='/images/homme.webp' alt='фон' />
      <div className='bg-overlay'>
        <h1>Профессиональный ремонт и выкуп техники в Санкт-Петербурге</h1>
        <div className='form-container'>
          <p>Хотите продать или отремонтировать технику?</p>
          <p>Оставьте свои данные, и мы вам перезвоним</p>
          <form onSubmit={handleSubmit}>
            <input
              name='name'
              type='text'
              placeholder='Имя клиента'
              value={formData.name}
              onChange={handleChange}
              required
            />
            <InputMask
              mask='+7 (999) 999-99-99'
              value={formData.phone}
              onChange={handleChange}
              name='phone'
              type='tel'
              maskChar={null}
            >
              {(inputProps) => (
                <input
                  {...inputProps}
                  className={`form-input ${error ? 'error' : ''}`}
                  placeholder='+7 (XXX) XXX-XX-XX'
                  required
                />
              )}
            </InputMask>
            {error && <p className='error-message'>{error}</p>}

            <select
              name='type'
              value={formData.type}
              onChange={handleChange}
              required
            >
              <option value='' disabled>Выберите тип техники</option>
              <option value='phone'>Телефон</option>
              <option value='tablet'>Планшет</option>
              <option value='laptop'>Ноутбук</option>
              <option value='watch'>Часы</option>
              <option value='computer'>Компьютер</option>
            </select>

            {formData.type === 'phone' && (
              <>
                <select
                  name='brand'
                  value={formData.brand}
                  onChange={handleChange}
                  required
                >
                  <option value='' disabled>Выберите бренд техники</option>
                  <option value='other'>Другое</option>
                  {Object.keys(brands).map((brand, index) => (
                    <option key={index} value={brand}>{brand}</option>
                  ))}
                </select>

                {formData.brand === 'other' ? (
                  <input
                    name='otherBrand'
                    type='text'
                    placeholder='Введите бренд'
                    value={formData.otherBrand}
                    onChange={handleChange}
                    required
                  />
                ) : (
                  <select
                    name='model'
                    value={formData.model}
                    onChange={handleChange}
                    required
                  >
                    <option value='' disabled>Выберите модель телефона</option>
                    {formData.brand && brands[formData.brand].map((model, index) => (
                      <option key={index} value={model}>{model}</option>
                    ))}
                  </select>
                )}
              </>
            )}

            {formData.type !== 'phone' && (
              <>
                <input
                  name='otherBrand'
                  type='text'
                  placeholder='Введите бренд'
                  value={formData.otherBrand}
                  onChange={handleChange}
                  required
                />
                <input
                  name='otherModel'
                  type='text'
                  placeholder='Введите модель'
                  value={formData.otherModel}
                  onChange={handleChange}
                  required
                />
              </>
            )}

            <select
              name='condition'
              value={formData.condition}
              onChange={handleChange}
              required
            >
              <option value='' disabled>Выберите состояние устройства</option>
              <option value='новое'>Новое</option>
              <option value='Б/У'>Использованное</option>
              <option value='с повреждениями'>Сломанное</option>
            </select>

            <textarea
              name='description'
              placeholder='Дополнительная информация'
              value={formData.description}
              onChange={handleChange}
            />

            <select
              name='serviceType'
              value={formData.serviceType}
              onChange={handleChange}
              required
            >
              <option value='' disabled>Выберите тип услуги</option>
              <option value='продажа'>Продажа</option>
              <option value='ремонт'>Ремонт</option>
            </select>

            <button type='submit' disabled={isSubmitting}>
              {isSubmitting ? 'Отправка...' : 'Отправить'}
            </button>
            {formError && <p className='error-message'>{formError}</p>}
          </form>
        </div>
      </div>
    </div>
  );
}
